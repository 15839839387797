/*global window,document,jQuery,Foundation*/
jQuery(document).ready(function ($) {
    'use strict';

    function initProcesSlider() {
        if (Foundation.MediaQuery.atLeast('large') === false) {
            var $nonInitializedSliders = $('.ProcesSlider-slider:not(.slick-initialized)');

            if ($nonInitializedSliders.length > 0) {
                $nonInitializedSliders.slick({
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    variableWidth: true,
                    infinite: false,
                    arrows: false,
                    dots: false
                });
            }
        }
    }

    $(window).on('resize', Foundation.util.throttle(function () {
        initProcesSlider();
    }, 300));

    initProcesSlider();
});

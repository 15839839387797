jQuery(document).ready(function ($) {
    'use strict';

    var foldSpecTable = $('.foldSpecTable');
    var tableTriggerSelector = $('.readmore-trigger');
    var tableActiveClass = "additional-attributes--hide";
    var tableRowsVisible = 7;

    foldSpecTable.each(function () {
        $(this).find('tbody tr').each(function(index) {
            if (index > (tableRowsVisible - 1)) {
                $(this).addClass(tableActiveClass);
            }
        })
    });

    tableTriggerSelector
        .off('click')
        .on("click", function (e) {
            e.preventDefault;

            foldSpecTable.each(function () {
                $(this).find('tbody tr').each(function(index) {
                    if (index > (tableRowsVisible - 1)) {
                        $(this).toggleClass(tableActiveClass);
                    }
                })
            });

            tableTriggerSelector.find('span').text(function(i, text) {
                return text === $(this).data('open-title') ? $(this).data('close-title') : $(this).data('open-title');
            }).toggleClass('active');
        });
});

/*global document, window, jQuery, Foundation*/

window.imageOverview = window.imageOverview || {
    selector: '.imageOverview-item-link',
    loadingText: 'Loading image #%curr%...',
    prevText: 'Previous image (Left arrow key)',
    nextText: 'Next image (Right arrow key)',
    errorText: '<a href="%url%">The image #%curr%</a> could not be loaded.',
    showRotationMessage: false
};

jQuery(document).ready(function ($) {
    'use strict';

    $('.popup-gallery').each(function () {
        $(this).magnificPopup({
            delegate: window.imageOverview.selector,
            type: 'image',
            tLoading: window.imageOverview.loadingText,
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 0], // Don't preload, else it will cause problems with the responsive image solution
                arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button

                tPrev: window.imageOverview.prevText, // title for left button
                tNext: window.imageOverview.nextText // title for right button
            },
            image: {
                tError: window.imageOverview.errorText
            },
            preloader: false,
            callbacks: {
                open: function () {
                    // move the buttons inside the container to overlay the image
                    $('.mfp-content').append($('.mfp-arrow'));
                },
                afterChange: function () {
                    var currentItem = this.currItem,
                        captionHtml = '',
                        titleHtml = '',
                        $contentContainer = this.contentContainer,
                        $parentElement = currentItem.el.parentsUntil('.imageOverview-item').parent(),
                        rotationMessage = '';

                    if (window.imageOverview.showRotationMessage === true) {
                        rotationMessage = '<div class="popupGallery-topBar-rotateNotice hide-for-medium">'
                            + '<span class="show-for-portrait"><i class="popupGallery-topBar-rotateNotice-icon"></i>'
                            + ' Roteer uw scherm voor een grotere weergave</span>'
                            + '</div>';
                    }

                    // check if the overlay is already initialized
                    // the open even didn't fire until after the first afterChange
                    if (!$contentContainer.hasClass('popupGallery')) {
                        // add some extra markup to the content container
                        // use visibility classes to remove the need for custom styling
                        // see http://foundation.zurb.com/docs/components/visibility.html
                        $contentContainer.addClass('popupGallery').prepend(
                            '<div class="popupGallery-topBar">'
                            + '<div class="popupGallery-topBar-title show-for-medium"><!-- --></div>'
                            + rotationMessage
                            + '</div>'
                        ).append(
                            '<div class="popupGallery-bottomBar">'
                            + '<div class="popupGallery-bottomBar-caption show-for-medium"><!-- --></div>'
                            + '</div>'
                        );
                    }

                    // remove any type modifier class names and ad the current one
                    $contentContainer.removeClass(
                        'popupGallery--' + this.types.join(' popupGallery--')
                    ).addClass(
                        'popupGallery--' + currentItem.type
                    );

                    if ($parentElement.length === 1) {
                        captionHtml = $parentElement.find('figcaption').html();
                        titleHtml = $parentElement.find('.imageOverview-item-title').html();

                        // if the element is empty fallback to an empty string
                        if (captionHtml === undefined) {
                            captionHtml = '';
                        }
                        if (titleHtml === undefined) {
                            titleHtml = '';
                        }
                    }

                    $contentContainer.find('.popupGallery-topBar-title').html(titleHtml);
                    $contentContainer.find('.popupGallery-bottomBar-caption').html(captionHtml);
                }
            }
        });
    });

    function setRemainingAriaLabel(activeSize) {
        $('.imageOverview-countRemaining-link').each(function () {
            if ($(this).attr('data-originallabel') === undefined) {
                $(this).attr('data-originallabel', $(this).attr('aria-label'));
            }

            var count = $(this).find('.imageOverview-countRemaining-count.show-for-' + activeSize + '-only').text();

            if (count) {
                $(this).attr('aria-label', $(this).attr('data-originallabel') + ': ' + count);
            } else {
                $(this).attr('aria-label', $(this).attr('data-originallabel'));
            }
        });
    }

    function setLinkHrefs(activeSize) {
        $('.imageOverview-item-link').each(function () {
            if ($(this).attr('data-size-' + activeSize) !== undefined) {
                $(this).attr('href', $(this).attr('data-size-' + activeSize));
            }
        });
    }

    $('.imageOverview-countRemaining-link').on('click', function (event) {
        event.preventDefault();
    });

    $('.imageOverview-countRemaining-count').click(function () {
        // declare the parent item
        var $parent = $(this).parentsUntil('.imageOverview-container').parent(),
            // get the total amount of items
            totalItems = $parent.find('.imageOverview-item').length,
            // get the value how much items are hidden after this div
            count = $(this).find('.imageOverview-countRemaining-count-remaining').text(),
            // take the total amount of items minus the hidden items
            clickedItem = totalItems - count;

        // trigger a click on the last visible item.
        $parent.find('.imageOverview-item:nth-child(' + clickedItem + ') a').trigger('click');
    });

    /*jslint unparam: true*/
    $(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {
        setRemainingAriaLabel(newSize);

        setLinkHrefs(newSize);

        var $popupImage = $('.mfp-img'),
            $currentLink;

        if ($popupImage.length === 1) {
            $currentLink = $('.imageOverview-item-link[data-size-' + oldSize + '="' + $popupImage.attr('src') + '"]');
            $popupImage.attr('src', $currentLink.attr('data-size-' + newSize));
        }
    });
    /*jslint unparam: false*/

    if (Foundation !== undefined && Foundation !== null && Foundation.MediaQuery !== undefined
            && Foundation.MediaQuery !== null && typeof Foundation.MediaQuery.current === 'string') {

        // If the current MediaQuery is still empty, delay the calculation by 500ms
        window.setTimeout(function () {
            setRemainingAriaLabel(Foundation.MediaQuery.current);
            setLinkHrefs(Foundation.MediaQuery.current);
        }, (Foundation.MediaQuery.current === '' ? 500 : 0));
    }
});

/*global document, event, jQuery */
jQuery(document).ready(function ($) {
    'use strict';

    var checkbox = $('.powermail_check_inner input[type="checkbox"]'),
        radio = $('.powermail_radio_inner input[type="radio"]'),
        combined = $('.powermail-customInput'),
        iconPath =  window.svgPath || '/typo3conf/ext/site_brgm_fe/Resources/Public/Icons/sprite.svg',
        successIcon;

        if(iconPath) {
            successIcon = '<svg class="Icon Icon--success u-color--successGreen"><use href="' + iconPath + '#Icon--success' + '"></use></svg>';
        }

    radio.addClass('js-is-hiden');
    checkbox.addClass('js-is-hiden');

    $('<a href="#" class="powermail-customRadio powermail-customInput" data-test="form-radio-link"></a> ').insertAfter(radio.parent().find('label'));
    $('<a href="#" class="powermail-customCheckbox powermail-customInput" data-test="form-checkbox-link"></a>').insertAfter(checkbox.parent().find('label'));

    combined.on('keypress', function (e) {
        if (e.which === 32) {
            e.preventDefault();
            $(this).trigger('click');
        }
    });

    $(document).on('click', '.powermail-customInput', function (event) {
        event.preventDefault();

        if ($(this).hasClass('powermail-customRadio') || $(this).hasClass('powermail-customCheckbox')) {
            $(this).parent().find('input[type="radio"], input[type="checkbox"]').trigger('click');
        }
    });

    $(document).on('mouseenter', '.powermail-customRadio', function () {
        $(this).parents('.powermail_fieldwrap_radio_stars').find('.powermail-customRadio').each(function () {
            // Use the class 'hover' here instead of the class 'active',
            // so it won't affect its clicked position visually on mouse leave
            $(this).addClass('hover');
            if ($(this).is(':hover')) {
                return false;
            }
        });
    });

    $(document).on('mouseleave', '.powermail-customRadio', function () {
        $(this).parents('.powermail_fieldwrap_radio_stars').find('.powermail-customRadio').each(function () {
            $(this).removeClass('hover');
        });
    });
});

/*global document, window, jQuery, $.magnificPopup*/

jQuery(document).ready(function ($) {
    'use strict';

    $('.js-popupGallery').each(function() {
        $(this).magnificPopup({
            delegate: '.js-popupGalleryLink',
            type: 'image',
            fixedContentPos: true,
            mainClass: 'mfp-bg-gallery',
            closeMarkup: '<button title="%title%" type="button" class="mfp-close"></button>' ,
            gallery: {
                enabled: true
            },
            callbacks: {
                buildControls: function() {
                    if(this.arrowLeft && this.arrowRight) {
                        // re-appends controls inside the main container
                        this.contentContainer.find('.mfp-figure').append(this.arrowLeft.add(this.arrowRight));
                        this.contentContainer.find('figcaption').remove();
                    }
                },

                open: function () {
                    // Remove margin right that was set by magnific by default
                    $('html').css({
                        'margin-right': 'auto'
                    });
                }
            }
        });
    });
});

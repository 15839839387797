/*global window, Modernizr*/
(function () {
    'use strict';

    window.MaxServ = window.MaxServ || {};

    // RequestAnimationFrame fallback for prefixes + old browsers
    window.MaxServ.requestAnimationFrame = window.requestAnimationFrame
        || window.webkitRequestAnimationFrame
        || window.mozRequestAnimationFrame
        || window.msRequestAnimationFrame
        || function (callback) {
            return window.setTimeout(callback, 1000 / 60);
        };

    window.MaxServ.transitionEndEventName = (document.documentElement.style.hasOwnProperty('WebkitTransition')) ? 'webkitTransitionEnd' : 'transitionend';

    window.MaxServ.focusableElements = 'a, input, select, textarea, button, iframe';

    window.MaxServ.setTransformTranslate = function (element, x, y) {
        if (window.Modernizr !== undefined && window.Modernizr.csstransforms3d === true) {
            element.style.webkitTransform = 'translate3d(' + x + 'px, ' + y + 'px, 0)';
            element.style.transform = 'translate3d(' + x + 'px, ' + y + 'px, 0)';
        } else {
            element.style.msTransform = 'translate(' + x + 'px, ' + y + 'px)';
            element.style.webkitTransform = 'translate(' + x + 'px, ' + y + 'px)';
            element.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
        }
    };
}());

/*global document, window, jQuery, Foundation*/

jQuery(document).ready(function ($) {
    'use strict';

    var currentMq = Foundation.MediaQuery.current;

    /*
    The function below is responsible for switching the tabs when clicked.
    It switches both the tabs and the accordion buttons even if
    only the one or the other can be visible on a screen. We prefer
    that in order to have a consistent selection in case the viewport
    changes (e.g. when you resize the browser window or flip your
    device from portrait mode to landscape).
    */
    function tabControl() {
        var tabs = $('.TabbedSeducer').find('.TabbedSeducer-tabs');

        if (tabs.is(':visible')) {
            tabs.find('.TabbedSeducer-tabsListLink').on('click', function (e) {
                e.preventDefault();

                var target = $(this).attr('href'),
                    tabs = $(this).parents('.TabbedSeducer-tabs'),
                    buttons = tabs.find('.TabbedSeducer-tabsListLink'),
                    item = tabs.parents('.TabbedSeducer').find('.TabbedSeducer-panel');

                buttons.removeClass('is-active');
                item.removeClass('is-active');
                $(this).addClass('is-active');
                $(target).addClass('is-active');
            });
        } else {
            $('.TabbedSeducer-panel').on('click', function (e) {
                var container = $(this).parents('.TabbedSeducer'),
                    currId = $(this).attr('id');

                if (e.target !== this) {
                    // Prevents collapsing of the accordion while clicking on the content, instead of the title tab
                    return;
                }

                $(this).toggleClass('is-active');
                $(this).siblings('.TabbedSeducer-panel').removeClass('is-active');

                container.find('.TabbedSeducer-tabs .TabbedSeducer-tabsListLink').removeClass('is-active');
                container.find('.TabbedSeducer-tabs .TabbedSeducer-tabsListLink[href$="#' + currId + '"]').addClass('is-active');
            });
        }
    }

    /*
    Initialize the accordion and tabs:
    */
    tabControl();

    /*
    Collapse all accordion tabs on small and medium screen on initialization
     */
    if (Foundation.MediaQuery.atLeast('large') === false) {
        $('.TabbedSeducer-panel.is-active').trigger('click');
    }

    /*
    We also apply the switch when a viewport change is detected on the fly
    (e.g. when you resize the browser window or flip your device from
    portrait mode to landscape).
    */
    $(window).on('resize', Foundation.util.throttle(function () {
        var parentContainer = $('.TabbedSeducer');

        if (currentMq !== Foundation.MediaQuery.current) {
            if (Foundation.MediaQuery.atLeast('large') === true) {
                parentContainer.each(function () {
                    if ($(this).find('.TabbedSeducer-panel.is-active').length === 0) {
                        $(this).find('.TabbedSeducer-panel').first().trigger('click');
                    }
                });
            }

            // Remove the event handler for "click", otherwise it will be fired multiple times after a window resize.
            // Also clean existing event handlers, so only the correct "click" will be available on the current viewport
            $('.TabbedSeducer-tabsListLink').off('click');
            $('.TabbedSeducer-panel').off('click');

            // Reinitialize the correct click events
            tabControl();
            currentMq = Foundation.MediaQuery.current;
        }
    }, 300));
});

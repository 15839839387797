/*global document, window, jQuery*/

jQuery(document).ready(function ($) {
    'use strict';
    var url = window.location.toString(),
        venue;

    if (url.indexOf('?vestiging=') !== -1) {
        venue = url.match(/\\?vestiging=([\w%\d]*)$/, 'i');

        if (venue.length > 1) {
            venue = decodeURIComponent(venue[1]);

            window.setTimeout(function () {
                $('.venueSelector option').each(function () {
                    if ($(this).text().toLowerCase() === venue.toLowerCase()) {
                        $(this).prop('selected', 'selected');
                        $(this).parents('select').trigger('change');
                    }
                });
            }, 1000);
        }
    }
});

/*global window,document,jQuery,Foundation*/
jQuery(document).ready(function ($) {
    'use strict';

    function initImageOverview() {
        if (Foundation.MediaQuery.atLeast('large') === false) {
            // Always unslick the slider here,
            // otherwise issues when switching from viewport (window resize etc.)
            $('.js-imageOverviewSlider.slick-slider').slick('unslick');
            $('.js-imageOverviewSlider').slick({
                slidesToShow: 1,
                arrows: false,
                dots: true
            });
        } else if (Foundation.MediaQuery.atLeast('large') === true) {
            $('.js-imageOverviewSlider.slick-slider').slick('unslick');
        }
    }

    $(window).on('resize', Foundation.util.throttle(function () {
        initImageOverview();
    }, 300));

    initImageOverview();
});

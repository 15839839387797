/*global window,document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    $(".button-with-anchor").on('click',function (event) {
        event.preventDefault();
        window.location.hash = 'scrollToContainerWithAnchor';

        $('html, body').animate({
            scrollTop: $(".element-frame-container-with-anchor").offset().top - 80
        }, 1000);

        $('.element-layout-hidden-block').css("display", "block");
    });

    if (window.location.hash === '#scrollToContainerWithAnchor') {
        $('html, body').animate({
            scrollTop: $(".element-frame-container-with-anchor").offset().top - 80
        }, 1000);
    }
});
